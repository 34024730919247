import React from "react";
import {Link, navigate} from 'gatsby';
import * as Icon from 'react-feather';
import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
{
    strapiSiteLogo{
      id
      blackLogo{
        id
        name
        url
      }
      whiteLogo{
        id
        name
        url
      }
    }
  }
`

const Navbar = () => {

    const data = useStaticQuery(query)
    const {strapiSiteLogo: {blackLogo, whiteLogo}} = data

    const [menu, setMenu] = React.useState(true)
 
    const toggleNavbar = () => {
        setMenu(!menu)
    }

    React.useEffect(() => {
        let elementId = document.getElementById("header");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elementId.classList.add("is-sticky");
            } else {
                elementId.classList.remove("is-sticky");
            }
        });
        window.scrollTo(0, 0); 
    })
 
    const classOne = menu ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
    const classTwo = menu ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

    return (
        <header id="header" className="headroom">
            <div className="startp-nav">
                <div className="container">
                    <nav className="navbar navbar-expand-md navbar-light">
                        <div class="col-lg-3 col-sm-3">
                            <Link to="/" onClick={toggleNavbar} className="navbar-brand col-lg-9 col-sm-9">
                                <img 
                                    src={blackLogo.url} 
                                    alt="CodeOfDutyLogo"
                                />
                            </Link>
                        </div>
                        

                        <button 
                            onClick={toggleNavbar} 
                            className={classTwo}
                            type="button" 
                            data-toggle="collapse" 
                            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" 
                            aria-expanded="false" 
                            aria-label="Toggle navigation"
                        >
                            <span className="icon-bar top-bar"></span>
                            <span className="icon-bar middle-bar"></span>
                            <span className="icon-bar bottom-bar"></span>
                        </button>

                        <div className={classOne} id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item">
                                    <Link to="/" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                        Home
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/#" activeClassName="active" onClick={e => e.preventDefault()} className="nav-link">
                                        Software Development <Icon.ChevronDown />
                                    </Link>

                                    <ul className="dropdown-menu">
                                        <li className="nav-item">
                                            <Link to="/#" activeClassName="active" onClick={e => e.preventDefault()} className="nav-link">
                                                Technologies <Icon.ChevronDown />
                                            </Link>

                                            <ul className="dropdown-menu">
                                                <li className="nav-item">
                                                    <Link to="/cloud-applications" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                        Cloud Applications
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/services/dev-ops" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                        DevOps / DevSecOps
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/services/mobile-development-processes" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                        Mobile
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/services/web-2-0-3-0" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                        Web 2.0 / Web 3.0
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/services/ui-ux-and-cdn" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                        UI/UX and CDN
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/#" activeClassName="active" onClick={e => e.preventDefault()} className="nav-link">
                                                Solutions <Icon.ChevronDown />
                                            </Link>

                                            <ul className="dropdown-menu">
                                                <li className="nav-item">
                                                    <Link to="/services/iot" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                    IoT
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/services/modern-keyword-analysis" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                    Data Sience
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/machine-learning" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                    Machine Learning and AI
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/services/dev-ops" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                    DevOps
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/services/rpa" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                    RPA
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>

                                        {/* <li className="nav-item">
                                            <Link to="/it-startup-2" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                
                                            </Link>
                                        </li>
 
                                        <li className="nav-item">
                                            <Link to="/iot" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                del IoT
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="/hosting" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                Del Hosting
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="/machine-learning" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                Del Machine Learning
                                            </Link>
                                        </li> */}

                                        {/* <li className="nav-item">
                                            <Link to="/machine-learning-2" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                Machine Learning 2
                                            </Link>
                                        </li>  */}

                                        {/* <li className="nav-item">
                                            <Link to="/bigdata-analytics" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                Bigdata Analytics
                                            </Link>
                                        </li> */}

                                        {/* <li className="nav-item">
                                            <Link to="/digital-agency" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                Digital Agency
                                            </Link>
                                        </li> */}

                                        {/* <li className="nav-item">
                                            <Link to="/digital-agency-portfolio" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                Digital Agency Portfolio
                                            </Link>
                                        </li> */}
  
                    
                                    </ul>
                                </li>

                                <li className="nav-item">
                                    <Link to="/#" onClick={e => e.preventDefault()} className="nav-link">
                                        Modernization <Icon.ChevronDown />
                                    </Link>

                                    <ul className="dropdown-menu">
                                        <li className="nav-item">
                                            <Link to="/#" activeClassName="active" onClick={e => e.preventDefault()} className="nav-link">
                                                Cloud Adaption <Icon.ChevronDown />
                                            </Link>
                                            <ul className="dropdown-menu">
                                                <li className="nav-item">
                                                    <Link to="/services/microservices" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                        Microservices
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/#" activeClassName="active" onClick={e => e.preventDefault()} className="nav-link">
                                                Cloud Migration <Icon.ChevronDown />
                                            </Link>

                                            <ul className="dropdown-menu">
                                                <li className="nav-item">
                                                    <Link to="/services/end-to-end-security-review" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                        End to end Security Review
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/services/gdpr" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                    GDPR and PCCI Compliant Easily
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                        
                                        <li className="nav-item">
                                            <Link to="/#" activeClassName="active" onClick={e => e.preventDefault()} className="nav-link">
                                                DataBase Migration <Icon.ChevronDown />
                                            </Link>
                                        
                                            <ul className="dropdown-menu">
                                                    <li className="nav-item">
                                                        <Link to="/services/sql" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                            SQL Migration
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item">
                                                        <Link to="/services/nosql" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                            NoSQL Migration
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item">
                                                        <Link to="/services/bigdata" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                            BigData
                                                        </Link>
                                                    </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <Link to="/#" activeClassName="active" onClick={e => e.preventDefault()} className="nav-link">
                                        Services <Icon.ChevronDown />
                                    </Link>

                                    <ul className="dropdown-menu">
                                        <li className="nav-item">
                                            <Link to="/services/digital-asset-evaluation" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                Digital Asset evaluation
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="/services/iot" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                            IoT
                                            </Link>
                                        </li>
 
                                        <li className="nav-item">
                                            <Link to="/services/lora-lorawan" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                LoRaWan
                                            </Link>
                                        </li>
                                    
                                    </ul>
                                </li>


                                <li className="nav-item">
                                    <Link to="/about-us" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                        About 
                                    </Link> 
                                </li>


                                <li className="nav-item">
                                    <Link to="/contact" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                        Contact
                                    </Link>
                                </li>
                            </ul>
                        </div>

                        <div className="others-option">
                            <Link to="/contact" className="btn btn-primary" onClick={e => navigate('/contact')}>
							    Support
                            </Link>
						</div>
                    </nav>
                </div>
            </div>
        </header>
    );
}

export default Navbar;